import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import Input from '../../components/Input';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import getValidationErrors from '../../services/getValidationErrors';
import Modal from 'react-modal';
import { Row, Col } from 'reactstrap';
import {
  FiCheckSquare,
  FiCalendar,
  FiArrowLeft,
  FiGrid,
  FiSearch,
  FiRefreshCcw,
  FiX,
} from 'react-icons/fi';

import { useAuth } from '../../context/auth';
import { useMenu } from '../../context/menu';
import { useCart } from '../../context/cart';

import api from '../../services/api';

import Table from '../../components/Table';
import Footer from '../../components/Footer';

import {
  Container,
  Content,
  HeaderAction,
  Tables,
  Balcony,
  SearchBar,
  PromotionArea,
  ModalFooter,
  ConfirmButton,
  CancelButton,
} from './styles';

import bannerPromotion from '../../assets/images/campanha.svg';
import { useOrders } from '../../context/ordersContext';
import { useTables } from '../../context/tablesContext';
import { ModalMGM } from '../../components/ModalMGM';

const RestaurantTables = props => {
  const footerRef = useRef(null);
  const inputTablesRef = useRef(null);
  const history = useHistory();
  const { closeCart } = useCart();
  const { setMenu } = useMenu();
  const {
    restaurantId,
    setTableNumber,
    setTableId,
    setSessionKey,
    setTableStatus,
    setIsBalcony,
    restaurantUseCommand,
    setBuyerCreatedByWaiter,
    setBuyerSelectedByWaiter,
    setBuyersCreatedByWaiter,
    setIsPos,
    stoneDevice,
    setRestaurantUseCommand,
  } = useAuth();

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.2))',
      border: 'none',
    },
  };

  const [tablesFilteredSearch, setTablesFilteredSearch] = useState();
  const [stonePendingTransaction, setStonePendingTransaction] = useState();
  const [modalStonePending, setModalStonePending] = useState(false);

  function toggleModalStonePending() {
    setModalStonePending(!modalStonePending);
  }

  const {
    tablesAvailable,
    tablesOccupied,
    closingTables,
    balcony,
    allTables,
    posTable,
  } = useTables();

  function handleSearchInputProduct(e) {
    try {
      const tableNumber = e;
      const tableFind = allTables.filter(table => {
        if (table?.table_number?.toString().startsWith(tableNumber)) {
          return table;
        }
        return '';
      });

      const tableOccpuiedFind = allTables.filter(table => {
        if (table?.table?.table_number?.toString().startsWith(tableNumber)) {
          return table;
        }
        return '';
      });

      if (tableFind.length > 0 || tableOccpuiedFind.length > 0) {
        setTablesFilteredSearch([...tableOccpuiedFind, ...tableFind]);
      } else {
        setTablesFilteredSearch([
          {
            status: 'none',
            id: 'abc124s',
          },
        ]);
      }
    } catch (error) {
      toast.error(`Produto não encontrado!`);
    }
  }

  async function handleNavigate(
    table_number,
    table_id,
    table_status,
    table_session,
  ) {
    if (table_number === -4) {
      if (!stoneDevice) {
        window.alert('O garçom não tem POS relacionado.');
        return false;
      }

      try {
        const response = await api.get(`waiter/pos/pending/${stoneDevice.id}`);
        if (response.data.length > 0) {
          setStonePendingTransaction(response.data[0]);
          toggleModalStonePending();
          return false;
        }
      } catch {
        console.log('error ao consultar.');
      }
    }

    setBuyerCreatedByWaiter('');
    setBuyerSelectedByWaiter('');
    setBuyersCreatedByWaiter('');
    setTableNumber(table_number);
    setTableId(table_id);
    setTableStatus(table_status);

    if (!!table_session) {
      setSessionKey(table_session);
    }

    history.push(`/menu`);
  }

  function handleNavigateFiltered(table) {
    setBuyerCreatedByWaiter('');
    setBuyerSelectedByWaiter('');
    if (table.table_type === 'balcony') {
      setIsBalcony(true);
      setIsPos(false);
      handleNavigate(table.table_number, table.id, table.status, table.key);
    } else {
      if (table?.status === 'available') {
        setIsBalcony(false);
        setIsPos(false);
        handleNavigate(table.table_number, table.id, table.status);
      }

      if (table?.status === 'open') {
        setIsBalcony(false);
        setIsPos(false);
        handleNavigate(
          table.table.table_number,
          table.table.id,
          table.status,
          table.key,
        );
      }

      if (table?.status === 'closing') {
        alert('Mesa em fechamento...');
      }
    }
  }

  useEffect(() => {
    closeCart();
  }, []);

  function clearInputTable() {
    const inputTable = document.getElementById('searchTable');

    inputTable.value = '';
  }

  const [loadingStoneCancel, setLoadingStoneCancel] = useState(false);

  const cancelStonePending = useCallback(async () => {
    setLoadingStoneCancel(true);
    try {
      await api.post('waiter/pos/cancel', {
        transaction_id: stonePendingTransaction.transaction_id,
      });

      setModalStonePending(false);
    } catch (err) {
      console.log(err);
    }
    setLoadingStoneCancel(false);
  }, [stonePendingTransaction]);

  return (
    <Container>
      <Content>
        <HeaderAction>
          <h1>
            {restaurantUseCommand.toString() === 'true' ? 'Comandas' : 'Mesas'}
          </h1>
          <div
            onClick={() => {
              history.push('/orderspending');
            }}
          >
            <FiArrowLeft size={18} color="#ff2b3a" /> <p>Voltar</p>
          </div>
        </HeaderAction>

        <SearchBar>
          <input
            name="tableNumber"
            type="text"
            placeholder="Digite a mesa desejada"
            onChange={e => handleSearchInputProduct(e.target.value)}
            id="searchTable"
          />

          {/* <button
              type="button"
              title="Mostrar todos"
              onClick={() => {
                setTablesFilteredSearch('');
              }}
            >
              <FiRefreshCcw />
            </button> */}
          <FiSearch />
          <FiX
            style={{ left: '90%' }}
            onClick={() => {
              setTablesFilteredSearch('');
              clearInputTable();
            }}
          />
        </SearchBar>

        {tablesFilteredSearch && tablesFilteredSearch.length > 0 ? (
          <Tables>
            {tablesFilteredSearch.map(table => (
              <Table
                status={table.status}
                table={table}
                key={table.id}
                onClick={() => {
                  if (
                    table?.table?.table_type === 'command' ||
                    table?.table_type === 'command'
                  ) {
                    setRestaurantUseCommand(true);
                  } else {
                    setRestaurantUseCommand(false);
                  }
                  handleNavigateFiltered(table);
                }}
              >
                {table.table_type === 'balcony' && 'Balcão'}

                {table.table_type === 'pos' && 'POS'}

                {table?.table_number > 0
                  ? table?.table_number
                  : '' || table?.table?.table_number}

                {table.table_type !== 'balcony' &&
                  'Balcão' &&
                  table.status !== 'available' && (
                    <p
                      style={{
                        borderTop: '1px solid #fff',
                        marginTop: 8,
                        paddingTop: 8,
                        fontSize: 14,
                      }}
                    >
                      R${' '}
                      {(
                        table.total_service_price -
                        table.payments?.reduce(
                          (accum, curr) => accum + Number(curr.payment_value),
                          0,
                        )
                      )
                        .toFixed(2)
                        .replace('.', ',')}
                    </p>
                  )}
              </Table>
            ))}
          </Tables>
        ) : (
          <Tables>
            {posTable &&
              posTable.length > 0 &&
              posTable.map(posTable => (
                <Table
                  key={posTable.id}
                  status={posTable.table_type === 'pos'}
                  onClick={() => {
                    handleNavigate(
                      posTable.table_number,
                      posTable.id,
                      posTable.status,
                      posTable.key,
                    );
                    setIsBalcony(false);
                    setIsPos(true);
                  }}
                >
                  <p>POS</p>
                </Table>
              ))}

            {closingTables.map(table => (
              <Table
                status={table.status}
                table={table}
                key={table.id}
                onClick={() => {
                  alert('Mesa em fechamento...');
                  setIsBalcony(false);
                  setIsPos(false);
                }}
              >
                <p>{!!table && table.table.table_number}</p>
                <p
                  style={{
                    borderTop: '1px solid #fff',
                    marginTop: 8,
                    paddingTop: 8,
                    fontSize: 14,
                  }}
                >
                  R${' '}
                  {(
                    table.total_service_price -
                    table.payments.reduce(
                      (accum, curr) => accum + curr.payment_value,
                      0,
                    )
                  )
                    .toFixed(2)
                    .replace('.', ',')}
                </p>
              </Table>
            ))}

            {tablesOccupied.map(table => (
              <Table
                status={table.status}
                key={table.id}
                table={table}
                onClick={() => {
                  if (table.table.table_type === 'command') {
                    setRestaurantUseCommand(true);
                  } else {
                    setRestaurantUseCommand(false);
                  }

                  handleNavigate(
                    table.table.table_number,
                    table.table.id,
                    table.status,
                    table.key,
                  );
                  setIsBalcony(false);
                  setIsPos(false);
                }}
              >
                <p> {!!table && table.table.table_number}</p>
                <p
                  style={{
                    borderTop: '1px solid #fff',
                    marginTop: 8,
                    paddingTop: 8,
                    fontSize: 14,
                  }}
                >
                  R${' '}
                  {(
                    table.total_service_price -
                    table.payments.reduce(
                      (accum, curr) => accum + Number(curr.payment_value),
                      0,
                    )
                  )
                    .toFixed(2)
                    .replace('.', ',')}
                </p>
              </Table>
            ))}
            {balcony &&
              balcony.length > 0 &&
              balcony.map(balcony => (
                <Table
                  key={balcony.id}
                  status={balcony.table_type === 'balcony'}
                  onClick={() => {
                    handleNavigate(
                      balcony.table_number,
                      balcony.id,
                      balcony.status,
                      balcony.key,
                    );
                    setIsBalcony(true);
                    setIsPos(false);
                  }}
                >
                  <p>Balcão</p>
                </Table>
              ))}

            {tablesAvailable.map(table => (
              <Table
                status={table.status}
                table={table}
                key={table.id}
                onClick={() => {
                  if (table.table_type === 'command') {
                    setRestaurantUseCommand(true);
                  } else {
                    setRestaurantUseCommand(false);
                  }
                  handleNavigate(table.table_number, table.id, table.status);
                  setIsBalcony(false);
                  setIsPos(false);
                }}
              >
                {!!table && table.table_number}
              </Table>
            ))}
          </Tables>
        )}
        {/* <PromotionArea>
          <a href="https://indique.buzz/takeat-indica" target='blank'> <img src={bannerPromotion} /></a>
        </PromotionArea> */}

        <Footer ref={footerRef} />
      </Content>

      <Modal
        isOpen={modalStonePending}
        onRequestClose={toggleModalStonePending}
        style={customStyles}
      >
        <Row>
          <Col>
            Existe uma venda em aberto na sua POS. Para prosseguir{' '}
            <strong>cancele</strong> a venda.
          </Col>
        </Row>
        <ModalFooter>
          <ConfirmButton
            onClick={cancelStonePending}
            disabled={loadingStoneCancel}
          >
            Cancelar venda
          </ConfirmButton>
          <CancelButton type="button" onClick={toggleModalStonePending}>
            Sair
          </CancelButton>
        </ModalFooter>
      </Modal>

      {/* <ModalMGM /> */}
    </Container>
  );
};

export default RestaurantTables;
